@import "../../../styles/constants.scss";
.card-chart-legend {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: var(--space-unit-md);
  min-height: 40px;
  align-items: center;

  @media (max-width: $mobile-breakpoint-3) {
    min-width: 400px;
    margin-bottom: var(--space-unit-md);
    justify-content: flex-start;
    margin-left: calc(var(--space-unit-xs) * -1);
  }
  .card-chart-legend-item {
    display: flex;
    align-items: center;
    margin-left: var(--space-unit-md);
    margin-top: var(--space-unit-xxs);
    margin-bottom: var(--space-unit-xxs);
    @media (max-width: $mobile-breakpoint-1) {
      text-align: left;
      margin-left: unset;
    }
    .card-chart-legend-color {
      width: var(--space-unit-md);
      height: var(--space-unit-md);
      border-radius: 4px;
      margin: 0 var(--space-unit-xs);

      &.main {
        background-color: var(--color-black);
      }
      &.compare {
        background-color: var(--color-warning-warning100);
      }
    }
    .card-chart-legend-text {
      white-space: nowrap;
      color: var(--color-grey-grey100);
    }
  }
  .card-chart-legend-toggle {
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-grey-grey50);
    padding-right: var(--space-unit-lg);
    margin-left: var(--space-unit-xs);
    height: 20px;

    @media (max-width: $mobile-breakpoint-1) {
      padding-right: var(--space-unit-xs);
    }
  }
}
