@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.settings-profile {
  margin-bottom: calc(6 * var(--space-unit-md));

  .settings-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: var(--space-unit-xxl);

    &:nth-last-child(3) {
      margin-bottom: var(--space-unit-xxl);
    }

    &:last-child {
      margin-top: 0;
    }

    .settings-container-title {
      text-align: left;

      &--danger {
        color: var(--color-alert-alert100);
        margin-top: var(--space-unit-lg);
      }
    }

    .settings-container-content {
      margin-top: var(--space-unit-lg);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .settings-container-content-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;

        fieldset {
          margin-right: 0;
          margin-left: 0;
        }

        & > :not(:last-child) {
          margin-right: var(--space-unit-xxl);

          @media (max-width: $mobile-breakpoint-2) {
            margin-right: 0;
          }
        }

        @media (max-width: $mobile-breakpoint-2) {
          flex-direction: column;
          margin: 0;
        }
      }

      .settings-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        padding-top: var(--space-unit-xs);

        .settings-checkbox {
          margin-right: var(--space-unit-lg);
        }
      }
    }

    .settings-container-description {
      margin-bottom: var(--space-unit-lg);
      text-align: start;

      &--muted {
        color: var(--color-grey-grey100);
        margin-bottom: 0;
      }
    }

    .profile-delete-error {
      font-size: var(--text-ssm);
      text-align: left;
      padding: var(--space-unit-xs);
      color: var(--color-alert-alert100);
    }

    .settings-container-text-input {
      @media (max-width: $mobile-breakpoint-2) {
        margin-bottom: 10px; // var(--space-unit-lg) - height of TextInput's error message element
        margin-right: 0;
        width: 100%;
      }
    }

    .settings-container-button {
      @media (max-width: $mobile-breakpoint-2) {
        align-self: flex-end;
      }
    }
  }

  .settings-divider {
    background-color: #f6dfdf !important;
  }
}
