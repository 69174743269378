@import "../../styles/constants.scss";

.places-input-item {
  display: flex;
  flex-direction: column;

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .address {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--color-grey-grey100);
  }
}
