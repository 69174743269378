@import "../../../styles/constants.scss";

.container {
  border: 1px solid var(--color-grey-greenish);
  border-radius: 10px;
}

.chart {
  max-height: 454px;
  overflow: auto;

  .gradient {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 49.88%, #ffffff 100%);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    bottom: 1px;
    height: var(--space-unit-xl);
    left: 0;
    position: absolute;
    right: 0;
  }
}

.beer {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: var(--space-unit-lg) var(--space-unit-lg) var(--space-unit-lg) var(--space-unit-lg);

  &:hover {
    background-color: var(--color-grey-grey25);
  }

  .logo {
    padding: var(--space-unit-xxs);
    margin-right: var(--space-unit-xs);
    background-color: var(--color-grey-grey50);

    .image {
      width: 100%;
      height: 100%;
    }
  }

  .info {
    flex-grow: 1;
    overflow: auto;

    .details {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--space-unit-xs);

      .name {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (min-width: $mobile-breakpoint-3) {
          margin-right: var(--space-unit-lg);
        }

        .faultyIcon {
          padding: 0 var(--space-unit-xs);
          align-self: stretch;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }

      .detailsRight {
        flex-shrink: 0;
      }

      .detailsRightType {
        color: var(--color-grey-grey100);
      }

      .value {
        margin-left: var(--space-unit-md);
      }
    }

    .line {
      height: var(--space-unit-sm);
      border: 1px solid var(--color-grey-grey50);
      border-radius: var(--border-radius);

      .fill {
        height: calc(100% + 2px);
        background-color: var(--color-background-black);
        margin-top: -1px;
        border-radius: var(--border-radius);
      }
    }
  }

  &.loading {
    .details {
      .name {
        background-color: var(--color-grey-grey50);
        border-radius: 1px;
        color: transparent;
        max-width: 200px;
      }

      .value {
        display: block;
        background-color: var(--color-grey-grey50);
        border-radius: 1px;
        color: transparent;
        min-width: var(--space-unit-xxl);
      }
    }
    .line {
      background-color: var(--color-grey-grey50);
      border-radius: 1px;
    }
  }
}

.tooltip {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: var(--color-white);
  padding: var(--space-unit-xs);
  display: block;
  min-width: 250px;
  max-width: 90vw;

  .header {
    display: flex;
    background-color: var(--color-white);
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--color-grey-greenish);
    padding-bottom: var(--space-unit-xs);

    .tooltipBeerName {
      margin-left: calc(-1 * var(--space-unit-xxs));
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: var(--space-unit-sm);
    padding-bottom: var(--space-unit-xxs);

    .info:first-child {
      border-right: 1px solid var(--color-grey-greenish);
      padding-right: var(--space-unit-md);
      margin-right: var(--space-unit-md);
    }

    .label {
      color: var(--color-grey-grey100);
      margin-bottom: var(--space-unit-xs);

      &.noMargin {
        margin-bottom: initial;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
    }
  }

  .positive {
    color: var(--color-success-success100);
  }

  .negative {
    color: var(--color-alert-alert100);
  }
}
