@import "./../../../styles/constants.scss";

.performance-card {
  display: flex;
  flex-direction: column;
  padding: var(--space-unit-lg);
  //max-width: 222px;
  align-items: center;
  flex: 1 1;

  .performance-card-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 100%;
  }

  .performance-card-title-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;

    @media (max-width: $mobile-breakpoint-2) {
      justify-content: flex-start;
    }

    .diana-button {
      padding: 0 var(--space-unit-xs);
      pointer-events: all;
    }
  }

  .performance-card-title {
    color: var(--color-grey-grey100);
  }

  .icon {
    box-sizing: border-box;
    height: 48px;
    width: 48px;
    min-width: 48px;
    border: 1px solid var(--color-grey-grey50);
    fill: var(--color-grey-grey50);
    border-radius: var(--border-radius);
    padding: var(--space-unit-md);
    margin-bottom: var(--space-unit-xs);
  }

  &.disabled {
    pointer-events: none;
  }

  &.active {
    .performance-card-title {
      color: var(--color-black);
    }

    .icon {
      background-color: var(--color-black);
      border: 1px solid var(--color-black);
      fill: var(--color-white);
    }
  }

  .performance-card-group {
    display: flex;
    flex-direction: column;
  }

  .performance-card-label {
    color: var(--color-grey-grey50);
    margin-top: var(--space-unit-sm);
    margin-bottom: var(--space-unit-xs);
  }

  .positive {
    color: var(--color-success-success100);
  }

  .negative {
    color: var(--color-alert-alert100);
  }

  .error-text,
  .no-outlet-text,
  .add-target,
  .no-target-text {
    color: var(--color-grey-grey50);
    margin: var(--space-unit-sm) auto;
    display: block;
    text-align: center;
  }

  &.loading {
    .performance-card-title,
    .performance-card-label,
    .performance-card-value {
      background-color: var(--color-grey-grey25);
      color: transparent !important;
    }

    .performance-card-value {
      min-width: var(--space-unit-lg);
      min-height: var(--space-unit-md);
    }
  }

  &.selectable {
    border: 1px solid var(--color-grey-grey50);
    border-radius: var(--border-radius);

    &:hover:not(.disabled) {
      cursor: pointer;
      border-color: var(--color-black);
    }

    &.selected {
      border-color: var(--color-black);

      &:not(.performance-card-current) {
        .icon {
          background-color: var(--color-warning-warning100);
          border: 1px solid var(--color-warning-warning100);
          fill: var(--color-white);
        }
      }
    }
  }

  .performance-card-info {
    flex-direction: column;
    display: flex;
    align-self: stretch;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
  }

  &.horizontal {
    max-width: 207px;
    margin-right: var(--space-unit-lg);

    &:last-child {
      margin-right: 0px;
    }

    .performance-card-info {
      flex-direction: row;
      display: flex;
      align-self: stretch;
      justify-content: space-between;
      flex-grow: 1;
      align-items: center;

      .performance-card-group:first-child {
        margin-right: var(--space-unit-lg);
      }
    }

    &.tablet {
      flex-basis: calc(50% - var(--space-unit-lg));
      max-width: calc(50% - var(--space-unit-lg)) !important;
      margin-right: var(--space-unit-lg);
      margin-bottom: var(--space-unit-lg);
      padding: var(--space-unit-md);
      box-sizing: border-box;
    }

    &.mobile {
      flex-basis: unset;
      max-width: 100% !important;
      margin-right: 0;
    }
  }

  &.tablet.horizontal,
  &.mobile.vertical {
    flex-direction: row;
    max-width: 100%;
    justify-content: space-between;

    &.vertical {
      border-bottom: 1px solid var(--color-grey-grey25);
      padding: var(--space-unit-lg) 0;

      &:last-child {
        border-bottom: none;
      }
    }

    &.horizontal {
      flex-direction: row;
    }

    &.dark {
      border-top: none;
      border-color: var(--color-black);
    }

    .icon {
      padding: var(--space-unit-md);
      margin-bottom: 0px;
      height: 64px;
      width: 64px;
      min-width: 64px;
    }

    .performance-card-content {
      flex-grow: 1;
      align-items: stretch;
      display: flex;
      padding-left: var(--space-unit-lg);
      flex-direction: column;
      max-width: calc(100% - 84px);

      .performance-card-title {
        text-align: left;
        margin-bottom: var(--space-unit-xs);
      }

      .performance-card-info {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
      }
    }

    .performance-card-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .performance-card-label {
        margin-top: 0px;
        margin-bottom: 1px;
        white-space: nowrap;
      }
    }

    .error-text,
    .no-outlet-text,
    .add-target,
    .no-target-text {
      text-align: left;
      margin: 0;
    }

    .no-target-text {
      max-width: unset;
    }

    .add-target {
      margin-top: var(--space-unit-xxs);
    }
  }

  &.dark {
    .performance-card-title {
      color: var(--color-grey-grey50);
    }

    .performance-card-label {
      color: var(--color-grey-grey100);
    }

    .performance-card-value:not(.positive):not(.negative) {
      color: var(--color-white);
    }

    .add-target {
      color: var(--color-white);
    }

    &.active {
      .performance-card-title {
        color: var(--color-white);
      }

      .performance-card-value {
        color: var(--color-white);
      }

      .icon {
        background-color: var(--color-white);
        fill: var(--color-black);
      }
    }

    &.loading {
      .performance-card-title,
      .performance-card-label,
      .performance-card-value {
        background-color: var(--color-grey-grey100);
      }
    }
  }
}

.text-dropdown > .text-dropdown > div > div {
  justify-content: center;
  max-width: 125px;
  margin: 0 auto;
}

.tablet .text-dropdown > .text-dropdown > div > div {
  justify-content: flex-start;
  max-width: inherit;
}
