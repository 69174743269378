@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.empty-page {
  flex: 1 1;
  padding: var(--space-unit-xl);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  height: calc(100vh - 160px);

  @media (max-width: $mobile-breakpoint-1) {
    padding: var(--space-unit-lg);
  }
  .empty-page-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
  }
}
