.flag-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  &.extended {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 calc(var(--space-unit-lg) * -1);
    .flag-element-container {
      margin: var(--space-unit-xs) var(--space-unit-lg);
      flex: 1;
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      min-width: 200px;
      max-width: 370px;
      max-height: 56px;
      min-height: 56px;
      box-sizing: border-box;
      background-color: unset;
      cursor: pointer;
      &.flag-selected {
        border-color: var(--color-black);
      }
      &.dummy {
        border: none;
        min-height: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
      .flag {
        width: 32px !important;
        height: 32px !important;
        margin: var(--space-unit-xs) calc(var(--space-unit-md));
      }
    }
  }
  .flag-choices {
    position: absolute;
    max-height: 40px;
    white-space: nowrap;
    right: 38px;
    left: unset;
    top: 0px;
    &.right {
      left: 38px;
      right: unset;
    }
    .hidden-flag {
      position: absolute;
      left: 0px;
      top: 0px;
    }
    &.hidden {
      left: 0px;
    }
  }
  .flag {
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    margin: 4px;
  }
}
