@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.devicesPage {
  .devicesFirstSection {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: flex-start;

    @media (max-width: $mobile-breakpoint-2) {
      width: 100%;
    }
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: var(--space-unit-lg);
    margin-bottom: var(--space-unit-lg);
    text-align: left;
  }

  .stepsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    margin-top: var(--space-unit-lg);
    width: 100%;

    @media (max-width: $mobile-breakpoint-2) {
      flex-direction: column;
    }

    .connectionStep {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      max-width: 33%;
      padding-right: var(--space-unit-xl);
      z-index: 1;
      box-sizing: border-box;

      @media (max-width: $mobile-breakpoint-2) {
        max-width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        padding-right: var(--space-unit-xs);
      }

      .connectionStepDescription {
        display: flex;
        flex-direction: row;

        @media (max-width: $mobile-breakpoint-2) {
          margin-bottom: var(--space-unit-lg);
        }

        .stepNumber {
          border-radius: 50%;
          background-color: var(--color-keg-green);
          color: var(--color-white);
          min-width: 24px;
          height: 24px;
          margin-right: var(--space-unit-lg);
          font-size: var(--text-sm);
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: $mobile-breakpoint-2) {
            margin-right: var(--space-unit-md);
          }
        }

        .stepText {
          text-align: left;
          margin-left: var(--space-unit-md);
        }
      }

      .connectionStepImage {
        min-height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: var(--space-unit-xl);
        @media (max-width: $mobile-breakpoint-2) {
          min-width: 80px;
        }
      }
    }
  }

  .validationCard {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 10px;
    padding: var(--space-unit-lg);
    color: var(--color-white);
    background-color: var(--color-black);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: var(--space-unit-lg);
    box-sizing: border-box;

    @media (max-width: $mobile-breakpoint-2) {
      flex-direction: column;
      align-items: flex-end;
      padding: var(--space-unit-md);
      padding-top: var(--space-unit-lg);
      margin-top: 0;
      margin-bottom: var(--space-unit-lg);
    }

    .validationCardContent {
      display: flex;
      width: 100%;

      @media (max-width: $mobile-breakpoint-2) {
        flex-direction: column;
      }
    }

    .addDeviceButton {
      @media (max-width: $mobile-breakpoint-2) {
        align-self: flex-end;
        margin-top: var(--space-unit-lg);
      }
    }

    .textinputDarkWrapper {
      margin-right: var(--space-unit-xl);
      max-height: 44px; // vertically align ErrorTextInput properly

      &:not(:first-child) {
        flex-grow: 1;
      }

      @media (max-width: $mobile-breakpoint-2) {
        margin-right: 0;
        max-height: unset;
        width: 100%;
      }
    }

    .textinputDarkWrapperError {
      @media (max-width: $mobile-breakpoint-2) {
        margin-bottom: var(--space-unit-md);
      }
    }

    .textinputDark {
      &[class*="focus"],
      &:hover,
      fieldset[class*="focus"],
      fieldset:hover {
        border-color: #f6f6f6;
      }

      input {
        color: #fff;
      }
    }
  }

  .deviceList {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;

    @media (max-width: $mobile-breakpoint-1) {
      margin-bottom: var(--space-unit-xl);
    }
  }

  .cardSubtitle {
    flex-grow: 1;
    text-align: left;
  }

  .outletDevicesCardContent {
    flex: 1;
    padding: 0 var(--space-unit-lg);
  }

  .deviceList {
    padding-left: var(--space-unit-lg);
  }
}
