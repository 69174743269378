@import "../../styles/constants.scss";

.select-item {
  display: flex;
  flex-direction: column;
  .select-item-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .select-item-username {
    color: var(--color-grey-grey100);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
