@import "../../styles/constants.scss";

.sales-filter-container {
  margin-top: var(--space-unit-lg);
  @media (min-width: $mobile-breakpoint-1) {
    margin-top: var(--space-unit-xs);
    padding-top: var(--space-unit-lg);
    margin-bottom: var(--space-unit-xxl);
    background-color: var(--color-background-white);
  }

  &.mobile {
    margin: var(--space-unit-xxl) 0;

    @media (min-width: $mobile-breakpoint-3) and (max-width: $mobile-breakpoint-1) {
      border-radius: var(--border-radius);
    }
  }

  &.fixed {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    transition: 0.3s top;

    .sales-filter {
      box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.18);
    }

    @media (max-width: $mobile-breakpoint-3) {
      &.scrolling-down {
        top: -156px; // distance from salesfilter to top page
      }
      &.scrolling-up {
        top: 0;
      }
    }
    @media (min-width: $mobile-breakpoint-3) and (max-width: $mobile-breakpoint-1) {
      border: none;
      border-radius: 0;
      padding-top: var(--space-unit-lg);
      margin-top: var(--space-unit-xs);
      margin-bottom: var(--space-unit-xxl);
      background-color: var(--color-background-white);
    }
  }

  .sales-filter-dropdown-container {
    display: flex;
    align-items: center;
  }

  .sales-filter-dropdown {
    display: flex;
    padding: var(--space-unit-xs) 0;
    align-items: center;
    margin-right: var(--space-unit-lg);

    &.filter-desktop {
      margin-right: 0;
    }
  }

  .sales-filter {
    background-color: var(--color-background-white);
    border-radius: var(--border-radius);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0);
    transition: box-shadow 0.5s;
    border: 1px solid var(--color-grey-greenish);

    @media (min-width: $mobile-breakpoint-1) {
      padding: var(--space-unit-xs) var(--space-unit-lg);
    }
    @media (max-width: $mobile-breakpoint-3) {
      justify-content: flex-start;
      border-radius: 0px;
    }

    .sales-filter-header-mobile {
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 76px;
      border-bottom: 1px solid #f6f6f6;
      padding: 0 var(--space-unit-md);

      .sales-filter-header-mobile-item {
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        overflow: hidden;
        padding: 0 var(--space-unit-lg);
        flex-grow: 1;
        flex: 33%;
        box-sizing: border-box;

        white-space: nowrap;

        @media (max-width: $mobile-breakpoint-3) {
          padding: 0 var(--space-unit-md);
        }

        &.sales-filter-beer {
          .sales-filter-header-mobile-item-subtitle {
            overflow: visible;
          }
        }

        &.selected {
          > .sales-filter-header-mobile-item-title {
            font-weight: 500;
          }

          > .base-border {
            width: calc(100% - 2 * var(--space-unit-lg));
            border-bottom: 1px solid var(--color-black);
            position: absolute;
            bottom: 0px;
            @media (max-width: $mobile-breakpoint-3) {
              width: calc(100% - 2 * var(--space-unit-md));
            }
          }
        }

        &.disabled {
          background-color: var(--color-grey-grey25);
          color: var(--color-grey-grey50);
          cursor: initial;
        }
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
        &:first-child,
        &:last-child {
          &.selected {
            > .base-border {
              width: calc(100% - 1 * var(--space-unit-lg));
            }
          }
        }

        .sales-filter-header-mobile-item-subtitle {
          text-align: left;
          font-size: 15px;
          flex-flow: nowrap;
          line-height: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
        }

        &:not(:last-child)::after {
          content: "";
          position: absolute;
          right: 0;
          border-left: 1px solid #f6f6f6;
          height: 60%;
        }
      }
      position: sticky;
      position: -webkit-sticky;
      left: var(--space-unit-lg);
      flex-basis: 100%;
    }
    .sales-filter-content-mobile {
      display: flex;
      padding: var(--space-unit-md) 0;
      overflow-x: auto;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
          padding: var(--space-unit-lg) 0;
        }
      }
      .chip {
        padding: var(--space-unit-xs);
        white-space: nowrap;
        border: 1px solid var(--color-grey-grey50);
        border-radius: var(--border-radius);
        margin-right: var(--space-unit-xs);
        color: var(--color-grey-grey100);
        font-size: var(--text-ssm);
        line-height: var(--text-ssm);
        &:first-child {
          margin-left: var(--space-unit-lg);
        }
        &.selected:not(.all-selected) {
          background-color: var(--color-black);
          color: var(--color-white);
          border-color: var(--color-black);
        }
        &.selected.all-selected {
          background-color: "transparent";
          color: var(--color-black);
          border-color: var(--color-black);
        }
        &.hidden-chip {
          width: 1px;
          padding: 0px;
          border: 1px solid black;
          opacity: 0;
        }
      }
    }

    .timeframe-modal {
      z-index: 4;
      padding: 0;
    }
  }
}
