@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.settings-page {
  .section {
    min-height: 700px;
    border-bottom: 2px solid black;
    margin: 32px;
  }
}
