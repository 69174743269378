$mobile-breakpoint-1: 1140px; // 1440px;
$mobile-breakpoint-2: 900px; //1187px;
$mobile-breakpoint-3: 700px;
$footer-height: 96px;

:root {
  --border: 2px;
  --border-radius: 10px;
  --transition-duration: 100ms;

  --sidebar-width: 219px;

  @media (max-width: $mobile-breakpoint-1) {
    --sidebar-width: var(--space-unit-xxxl);
  }

  --sidebar-height: 700px;

  --footer-height: 96px;
  --bottombar-height: 54px;

  --detail-page-header-height: 192px;
  --detail-page-header-height-mobile: 67px;

  --mobile-breakpoint-1: var(--mobile-breakpoint-1);
  --mobile-breakpoint-2: var(--mobile-breakpoint-2);
}

:export {
  footer-height: $footer-height;
  mobile-breakpoint-1: $mobile-breakpoint-1;
  mobile-breakpoint-2: $mobile-breakpoint-2;
  mobile-breakpoint-3: $mobile-breakpoint-3;
}
