@import "../../../styles/constants.scss";

.container {
  display: flex;
  position: sticky;
  z-index: 1;
  top: 0px;
  bottom: 0px;

  &.inversed {
    .sidebar {
      .sidebar-inside {
        background-color: "transparent";
      }
    }
  }
  &.overlay {
    width: 0;
    z-index: 1;
    .sidebar {
      transform: translateX(-100%);
      transition: 0.3s transform;
      z-index: 11;
    }

    &.opened {
      .sidebar {
        transform: translateX(0);
      }
    }
  }

  &.side {
    max-width: 0;
    transition: 0.3s max-width;

    .sidebar {
      transform: translateX(-100%);
      transition: 0.3s transform;
    }

    &.opened {
      max-width: calc(var(--sidebar-width));
      .sidebar {
        transform: translateX(0);
      }
    }
  }

  .sidebar {
    width: var(--sidebar-width);
    min-width: var(--sidebar-width);
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: absolute;

    .sidebar-inside {
      padding: 0 var(--space-unit-lg) 0 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      display: flex;
      flex-direction: column;
      flex: 1 1;

      @media (max-width: $mobile-breakpoint-1) {
        padding: 0;
      }

      .sidebarLanguagePicker {
        display: flex;
        padding: 0 var(--space-unit-lg);
      }
    }

    .sidebar-nav-button {
      height: 50px;
      align-items: center;
      display: flex;
      font-size: var(--text-base-size);
      margin-left: var(--space-unit-lg);
      cursor: pointer;
      color: var(--color-grey-grey100);
      text-decoration: none;
      margin-bottom: var(--space-unit-xs);
      text-transform: uppercase;
      outline: none;

      @media (max-width: $mobile-breakpoint-1) {
        padding: 0;
        min-width: unset;
        justify-content: center;
        margin-left: 0;
      }

      &.disabled {
        opacity: 0.4;
        cursor: default;
      }

      &:not(.disabled):hover {
        background-color: var(--color-white);
      }

      &.selected {
        background-color: var(--color-white);
        color: var(--color-primary-outdated);
      }

      .icon {
        margin-right: var(--space-unit-md);
      }
    }
  }

  .sidebar-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--color-background-overlay);
    z-index: 10;
  }
}
