@import "../../../styles/constants.scss";

.profileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .profileItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-left: var(--space-unit-lg);
  }

  .profileIcon {
    margin-right: var(--space-unit-xs);
  }

  .profileName {
    color: var(--color-black);
    @media (max-width: $mobile-breakpoint-2) {
      display: none;
    }
  }
}

.profile-menu-title {
  cursor: default !important;
  min-width: 300px;
  padding: var(--space-unit-lg) !important;
  margin-top: var(--space-unit-xs);

  &:hover {
    background-color: var(--color-white) !important;
  }

  > * {
    display: flex;
    flex-direction: column;
  }

  .profile-menu-email {
    color: var(--color-grey-grey100);
  }
}

.profile-menu-logout {
  border-top: 1px solid var(--color-grey25) !important;
  padding: var(--space-unit-xs) var(--space-unit-lg) !important;

  :hover {
    color: var(--color-alert-alert100);
  }
}

.profile-logout-modal {
  .profile-logout-modal-confirm {
    color: var(--color-alert-alert100);
  }
}
