.align-left {
  text-align: left;
}
.flex {
  display: flex;
}
.flex--align-center {
  display: flex;
  align-items: center;
}

.flex--column {
  display: flex;
  flex-direction: column;
}

.flex--grow {
  flex-grow: 1;
}

.margin-top,
.margin-top--md {
  margin-top: var(--space-unit-lg);
}

.margin-top--xxs {
  margin-top: var(--space-unit-xxs);
}

.margin-top--xs {
  margin-top: var(--space-unit-xs);
}

.margin-top--sm {
  margin-top: var(--space-unit-md);
}

.margin-top--lg {
  margin-top: var(--space-unit-xl);
}

.margin-top--xl {
  margin-top: var(--space-unit-xxl);
}

.margin-top--xxl {
  margin-top: var(--space-unit-xxl);
}

.margin-top--none {
  margin-top: 0;
}

.margin-bottom,
.margin-bottom--md {
  margin-bottom: var(--space-unit-lg);
}

.margin-bottom--xxs {
  margin-bottom: var(--space-unit-xxs);
}

.margin-bottom--xs {
  margin-bottom: var(--space-unit-xs);
}

.margin-bottom--sm {
  margin-bottom: var(--space-unit-md);
}

.margin-bottom--lg {
  margin-bottom: var(--space-unit-xl);
}

.margin-bottom--xl {
  margin-bottom: var(--space-unit-xxl);
}

.margin-bottom--xxl {
  margin-bottom: var(--space-unit-xxl);
}

.margin-bottom--none {
  margin-bottom: 0;
}

.margin-right,
.margin-right--md {
  margin-right: var(--space-unit-lg);
}

.margin-right--xxs {
  margin-right: var(--space-unit-xxs);
}

.margin-right--xs {
  margin-right: var(--space-unit-xs);
}

.margin-right--sm {
  margin-right: var(--space-unit-md);
}

.margin-right--lg {
  margin-right: var(--space-unit-xl);
}

.margin-right--xl {
  margin-right: var(--space-unit-xxl);
}

.margin-right--xxl {
  margin-right: var(--space-unit-xxl);
}

.margin-right--none {
  margin-right: 0;
}

.margin-left,
.margin-left--md {
  margin-left: var(--space-unit-lg);
}

.margin-left--xxs {
  margin-left: var(--space-unit-xxs);
}

.margin-left--xs {
  margin-left: var(--space-unit-xs);
}

.margin-left--sm {
  margin-left: var(--space-unit-md);
}

.margin-left--lg {
  margin-left: var(--space-unit-xl);
}

.margin-left--xl {
  margin-left: var(--space-unit-xxl);
}

.margin-left--xxl {
  margin-left: var(--space-unit-xxl);
}

.margin-left--none {
  margin-left: 0;
}
