@import "../../styles/constants.scss";

.overview-page {
  .grid {
    line {
      stroke: var(--color-grey-grey100);
    }
  }
  .today {
    margin-bottom: var(--space-unit-xxl);
    max-width: 100%;
    flex: 1;
    padding: 0px;

    @media (max-width: $mobile-breakpoint-2) {
      margin-bottom: var(--space-unit-xxl);
    }
    @media (max-width: $mobile-breakpoint-3) {
      padding: 0 var(--space-unit-lg);
    }
    .performance {
      flex: 1 1;
      align-self: stretch;
      align-items: center;
      display: flex;
      position: relative;
      padding: var(--space-unit-xxl) 0;

      @media (max-width: $mobile-breakpoint-2) {
        margin-top: var(--space-unit-xxl);
      }

      .axis {
        .tick {
          text {
            color: var(--color-grey-grey100);
          }
        }
      }

      .performanceTitle {
        color: var(--color-white);
        top: 0px;
        position: absolute;
        display: flex;
        align-items: center;

        .tooltip {
          margin-left: var(--space-unit-xs);
          --tooltip-hover-color: var(--color-white);
        }
      }
    }
  }

  .errorMessagePast {
    top: calc(var(--space-unit-xxl) + var(--space-unit-md));
    bottom: calc(var(--space-unit-xxl) + var(--space-unit-xs));
  }

  .summary-container {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(6 * var(--space-unit-md));
    flex-wrap: wrap;
    margin: 0 calc(var(--space-unit-md) * -1);

    .summary {
      flex: 1;
      min-width: 290px;
      margin: 0px var(--space-unit-md) var(--space-unit-lg) var(--space-unit-md);
    }

    @media (max-width: $mobile-breakpoint-2) {
      margin-bottom: var(--space-unit-xxl);
    }
    @media (max-width: $mobile-breakpoint-3) {
      padding: 0 var(--space-unit-lg);
    }
  }

  .recentSalesTargetWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-unit-xxl);

    @media (max-width: $mobile-breakpoint-1) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
}

.overviewUnknownOutlet {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-unit-xl);
}
