@import "../../../styles/constants.scss";

.sidebar-header {
  min-height: 113px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: var(--space-unit-xl);

  @media (max-width: $mobile-breakpoint-1) {
    min-height: var(--space-unit-xxxl);
    margin-left: 0;
  }

  .icon-logo {
    height: 32px;
    width: 129px;
  }

  &-logout-button {
    margin: var(--space-unit-lg);
    display: flex;
    align-items: center;
    color: var(--color-grey-grey100);
    text-decoration: none;
    .icon {
      margin: 0px var(--space-unit-xs);
    }
  }
}
