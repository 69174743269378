@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.product-page {
  padding-top: var(--space-unit-lg);

  .product-header {
    display: flex;
    flex: 1 1;
    justify-content: space-between;

    @media (max-width: $mobile-breakpoint-1) {
      flex-wrap: wrap;
    }

    @media (max-width: $mobile-breakpoint-3) {
      margin-top: var(--space-unit-lg);
    }
  }

  .product-insights-title {
    display: block;
    margin-bottom: var(--space-unit-lg);
    text-align: start;
  }

  .product-page-body {
    margin-top: var(--space-unit-xxl);

    @media (max-width: $mobile-breakpoint-3) {
      margin-top: var(--space-unit-lg);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--space-unit-xl);
    }
  }
}

.product-summary-group {
  border: 1px solid var(--color-grey-greenish);
  border-radius: 10px;
  box-sizing: border-box;
  padding: var(--space-unit-md) var(--space-unit-lg);
  // each element has var(--space-unit-xxl) between each other
  width: calc(25% - (3 * var(--space-unit-xxl) / 4));

  @media (max-width: $mobile-breakpoint-1) {
    // each element has var(--space-unit-lg) between each other
    width: calc(50% - (var(--space-unit-lg) / 2));
  }

  @media (max-width: $mobile-breakpoint-3) {
    margin-bottom: var(--space-unit-lg);
    width: 100%;
  }

  &:first-child,
  &:nth-child(2) {
    @media (max-width: $mobile-breakpoint-1) {
      margin-bottom: var(--space-unit-lg);
    }
  }

  &-title {
    color: var(--color-grey-grey100);

    &-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--space-unit-xs);
    }
  }

  &-logos {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &-logo {
    border: 1px solid var(--color-white);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
    box-sizing: border-box;
    flex-shrink: 0;
    height: 24px;
    width: 24px;

    &:not(:first-child) {
      margin-right: -6px;
    }

    &--ellipsis {
      padding: var(--space-unit-xxs);
    }

    &--ellipsis,
    &--fallback {
      background-color: var(--color-grey-grey50);
    }
  }

  &.loading {
    color: transparent !important;
    .product-summary-group-logo {
      background-color: var(--color-grey-grey25) !important;
    }
  }
}

.product-beverage-data {
  display: flex;
  flex-direction: column;
  min-height: fit-content;

  &--vertical {
    flex-direction: row;
  }

  &-item {
    &:not(.product-beverage-data-item--vertical) {
      margin-bottom: var(--space-unit-md);

      .product-beverage-data-label {
        margin-right: var(--space-unit-xxs);
      }
    }

    .product-beverage-data-label {
      color: var(--color-grey-grey100);
    }
  }

  &-item--vertical {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    .product-beverage-data-label {
      margin-bottom: var(--space-unit-xs);
    }
  }

  .diana-chip {
    margin: auto !important;
  }
}

.stock-management-modal {
  max-width: 500px;
  max-height: 90vh;

  @media (max-width: $mobile-breakpoint-3) {
    max-width: calc(100% - var(--space-unit-lg));
  }

  &-body {
    @media (max-width: $mobile-breakpoint-3) {
      max-height: 60vh;
      overflow-y: auto;
    }
    &-container {
      max-height: 40vh;
      overflow-y: auto;

      @media (max-width: $mobile-breakpoint-3) {
        max-height: unset;
        overflow-y: unset;
      }

      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
      }

      // Hide scrollbar in Firefox
      scrollbar-width: none;
    }

    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
    // Hide scrollbar in Firefox
    scrollbar-width: none;
  }
  &-checkbox {
    display: flex;
    flex-direction: column;
    margin-top: var(--space-unit-lg);

    @media (max-width: $mobile-breakpoint-3) {
      margin-top: 0;
      margin-bottom: var(--space-unit-xxl);
    }
    &-desc {
      margin-top: var(--space-unit-xs);
      color: var(--color-grey-grey100);
    }

    &-text {
      white-space: initial;
    }
  }
  .stock-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: var(--space-unit-lg) 0;
    &:first-child {
      margin-top: 0;
    }
    &-desc {
      display: flex;
      flex-direction: column;
      margin-right: var(--space-unit-xxl);
      flex: 1;
      overflow: hidden;

      @media (max-width: $mobile-breakpoint-3) {
        margin-right: var(--space-unit-xs);
      }

      &-title {
        color: var(--color-black);
        margin-bottom: var(--space-unit-xxs);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &-date {
        color: var(--color-grey-grey100);
      }
    }
    .stock-list-spinner {
      .diana-input-spinner-value {
        width: 33px !important;
      }
    }
  }
}

.product-beverage-details-modal {
  max-height: calc(100vh - var(--space-unit-md) * 2);
  width: 600px;

  @media (max-width: $mobile-breakpoint-1) {
    width: calc(100vw - var(--space-unit-md) * 2);
  }

  & &-header {
    padding-bottom: 0;
  }

  & &-body {
    overflow-y: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    text-align: center;

    .product-beverage-logo {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
      width: 92px;
      height: 92px;
      margin-bottom: var(--space-unit-lg);
      border-radius: 50%;
      flex-shrink: 0;
    }

    .product-beverage-logo--fallback {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .product-beverage-type {
      color: var(--color-grey-grey100);
      margin-top: var(--space-unit-xxs);
      margin-bottom: var(--space-unit-xxl);
    }

    .product-beverage-data {
      justify-content: space-between;
      margin-bottom: var(--space-unit-xxl);
      width: 100%;

      @media (max-width: $mobile-breakpoint-3) {
        flex-wrap: wrap;
      }

      &-item {
        align-items: center;
        flex-grow: 1;

        @media (max-width: $mobile-breakpoint-3) {
          border: 1px solid var(--color-grey-greenish);
          border-radius: 10px;
          box-sizing: border-box;
          flex-grow: 0;
          padding: var(--space-unit-xs);
          // each element has var(--space-unit-md) between each other
          width: calc(50% - var(--space-unit-md) / 2);
        }

        &:first-child,
        &:nth-child(3) {
          @media (max-width: $mobile-breakpoint-3) {
            margin-bottom: var(--space-unit-lg);
          }
        }
      }

      .product-beverage-data-divider {
        height: auto;

        @media (max-width: $mobile-breakpoint-3) {
          display: none;
        }
      }
    }

    .product-beverage-chart-title {
      color: var(--color-grey-grey100);
    }

    .product-beverage-chart-wrapper {
      box-sizing: border-box;
      margin-top: var(--space-unit-xxl);
      padding-left: var(--space-unit-md);
      padding-right: var(--space-unit-md);
      width: 100%;
    }

    .product-beverage-chart-missing {
      margin-top: var(--space-unit-md);
    }

    .product-beverage-badge {
      min-height: fit-content;
      margin-top: var(--space-unit-xxl);
      margin-bottom: var(--space-unit-md);
    }

    .performance-meter-wrapper {
      min-height: fit-content;
      margin-top: var(--space-unit-lg);
    }
  }

  & &-footer {
    place-content: center;
  }
}
