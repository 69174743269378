@import "../../../../styles/constants.scss";

.homescreen-modal {
  .homescreen-modal-content {
    display: flex;
    flex-direction: column;

    .homescreen-modal-illustration-img {
      width: 100%;
      margin-bottom: var(--space-unit-xl);
    }

    .homescreen-modal-instructions {
      display: flex;
      align-items: center;
      margin: 24px 0;
    }

    .homescreen-modal-icon-img {
      height: 26px;
      padding: 0 4px;
    }
  }
}
