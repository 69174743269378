@import "../../../styles/constants.scss";

$transition-duration: 200ms;

.insight-cards {
  display: flex;
  overflow: scroll;
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE and Edge

  &-wrapper {
    display: flex;
    position: relative;
    transition: height $transition-duration ease-out;
    // make badges stretch to the right edge of the expandable panel header
    width: calc(100% + var(--space-unit-lg) + var(--space-unit-md));
  }

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  @media (max-width: $mobile-breakpoint-3) {
    overflow: scroll;
  }

  .insight-cards-overflow {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 30%, var(--color-white) 100%);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: var(--space-unit-md);
  }

  .insight-card {
    border-radius: 50px;
    box-sizing: border-box;
    flex-shrink: 0;
    margin-right: var(--space-unit-md);
    margin-top: var(--space-unit-xs);
    transition: border-radius 0ms, border-color $transition-duration, margin $transition-duration;

    &.success {
      border-color: var(--color-success-success100);
    }

    &.warning {
      border-color: var(--color-warning-warning100);
    }

    &.expanded {
      border-color: var(--color-grey-greenish);
      border-radius: 10px;
      margin-right: var(--space-unit-xxl);
      width: 342px;

      @media (max-width: $mobile-breakpoint-1) {
        margin-right: var(--space-unit-md);
      }

      @media (max-width: $mobile-breakpoint-3) {
        width: 100%;
      }

      &:not(:only-child) {
        @media (max-width: $mobile-breakpoint-3) {
          width: calc(100% - var(--space-unit-xxl));
        }
      }
    }

    .insight-card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: var(--space-unit-xxs) var(--space-unit-xs);
      text-align: start;
      transition: height $transition-duration, padding $transition-duration;

      &.expanded {
        padding: var(--space-unit-lg);
      }

      .insight-content-wrapper {
        display: flex;
        flex-direction: column;
      }

      .insight-card-title {
        align-items: flex-start;
        display: flex;
        transition: margin $transition-duration;

        &.expanded {
          margin-bottom: var(--space-unit-md);
        }

        .insight-card-icon {
          flex-shrink: 0;
          margin-right: var(--space-unit-xs);
          margin-top: 3px; // nudge icon to align w/ first line of title

          &--green {
            background-color: var(--color-success-success100);
            border-radius: 50%;
            fill: var(--color-white);

            & path:first-child {
              fill: var(--color-success-success100);
            }
          }
        }
      }
    }

    .insight-card-logos {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-top: var(--space-unit-md);
    }

    .insight-card-logo {
      border: 1px solid var(--color-white);
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
      box-sizing: border-box;
      flex-shrink: 0;
      height: 48px;
      width: 48px;

      &:not(:first-child) {
        margin-right: calc(-1 * var(--space-unit-sm));
      }

      &--ellipsis {
        padding: var(--space-unit-sm);
      }

      &--ellipsis,
      &--fallback {
        background-color: var(--color-grey-grey50);
      }
    }
  }
}
