.modal-error {
  width: 100%;
  max-width: 560px;
  height: 100%;
  max-height: 446px;

  .modal-error-content {
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    .modal-error-graphic {
      position: relative;

      .line {
        position: absolute;
        left: calc(-1 * var(--space-unit-md));
        right: calc(-1 * var(--space-unit-md));
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .button-refresh {
      align-self: center;
    }
  }
}
