@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.installation-page {
  display: flex;
  flex-direction: column;

  .header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: var(--space-unit-lg);
    margin-bottom: var(--space-unit-lg);
  }

  .installation-page-status {
    display: flex;
    flex: 1 1;
    background-color: var(--color-white);
    margin: var(--space-unit-lg) 0;
    border-radius: 10px;
    justify-content: space-between;

    @media (max-width: $mobile-breakpoint-1) {
      flex-wrap: wrap;
    }

    .installation-page-infoicon {
      box-sizing: border-box;
      padding-left: var(--space-unit-md);
      padding-right: var(--space-unit-md);
      // each element has var(--space-unit-xxl) between each other
      width: calc(25% - (3 * var(--space-unit-xxl) / 4));

      @media (max-width: $mobile-breakpoint-1) {
        width: calc(50% - (var(--space-unit-xxl) / 2));
      }

      &:first-child,
      &:nth-child(2) {
        @media (max-width: $mobile-breakpoint-1) {
          margin-bottom: var(--space-unit-lg);
        }
      }
    }

    .overview-badge {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: var(--space-unit-xxl);
      margin-bottom: var(--space-unit-md);
      margin-top: var(--space-unit-md);

      .name {
        margin-left: 12px;
        color: var(--color-grey-grey100);
        font-weight: 700;
        margin-right: 12px;
      }
    }
  }

  .devicesRow {
    &.loading {
      display: grid;
      grid-gap: var(--space-unit-xxl);
      grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
      margin-bottom: var(--space-unit-xxl);
    }

    .outlet-panels {
      width: 100%;

      .outlet-panel {
        & > div:nth-child(2) {
          & > div {
            flex-direction: column; // hacky way of accessing expandable panel's internal element
          }
        }
      }

      .outlet-panel-tags {
        display: flex;

        @media (max-width: $mobile-breakpoint-2) {
          justify-content: space-between;
          margin-top: var(--space-unit-md);
          width: 106%; // overlap expandable panel's arrow icon's vertical space
        }

        .outlet-panel-tag {
          align-items: center;
          display: flex;
          width: 50px;

          &:last-child {
            @media (max-width: $mobile-breakpoint-2) {
              width: auto;
            }
          }

          @media (max-width: $mobile-breakpoint-2) {
            width: 25%;
          }

          @media (max-width: $mobile-breakpoint-3) {
            width: 46px;
          }

          &:not(:last-child) {
            margin-right: var(--space-unit-xxl);
          }

          .outlet-panel-tag-icon {
            margin-right: var(--space-unit-xs);
          }
        }
      }
    }
  }

  .installation-page-installation {
    display: grid;
    grid-gap: var(--space-unit-xxl);
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    margin-bottom: var(--space-unit-xxl);

    &.empty {
      display: initial;
    }
  }
}
