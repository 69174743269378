@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.pressurechamber-info {
  display: flex;
  width: 100%;

  &--centered {
    align-self: center;
    width: auto;
  }

  .keg-description {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: var(--space-unit-md);
    max-width: 65%;

    .keg-label {
      color: var(--color-grey-grey100);
    }

    .keg-brand,
    .keg-subbrand {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .keg-brand--empty {
      line-height: 2.66rem;
    }

    .keg-subbrand {
      margin-bottom: var(--space-unit-xs);
    }

    .keg-details {
      display: flex;
      justify-content: space-around;
      margin: var(--space-unit-sm) 0;
      position: relative;
      width: 100%;
    }

    .keg-lastdraught {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-unit-xs);
      width: 100%;

      .keg-label {
        margin-bottom: var(--space-unit-xxs);
      }
    }
  }
}
