@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.settings-user {
  padding-top: var(--space-unit-lg);

  @media (max-width: $mobile-breakpoint-2) {
    padding-bottom: var(--space-unit-xl);
  }

  .user-description {
    margin: var(--space-unit-lg) 0;
    text-align: left;
    line-height: 24px;
  }
  .filter {
    margin-bottom: var(--space-unit-lg);

    @media (max-width: $mobile-breakpoint-2) {
      width: auto;
    }

    .filter-title {
      @media (max-width: $mobile-breakpoint-2) {
        display: none;
      }
    }
  }
  .user-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    min-height: 72px;

    .info {
      display: flex;
      align-items: center;

      .indicator {
        border-radius: 50%;
        background-color: var(--color-success-success100);
        height: var(--space-unit-xs);
        // vertical margin to create space between tooltip and indicator
        margin: 10px var(--space-unit-xs) 10px 0;
        width: var(--space-unit-xs);
      }

      .name {
        margin-right: var(--space-unit-xs);
      }

      .username {
        color: var(--color-grey-grey100);
      }
    }

    .user-list-item-action {
      align-items: center;
      display: flex;
    }

    @media (max-width: $mobile-breakpoint-2) {
      flex-direction: column;
      padding: var(--space-unit-xxs) 0;

      .info {
        flex-direction: column;
      }

      .username {
        margin-top: var(--space-unit-xxs);
      }

      .indicator + .username {
        margin-top: 0;
      }

      .dropdown-container {
        margin-top: var(--space-unit-md);
        width: 100%;
        justify-content: center;
        display: flex;
        .inactive {
          opacity: 0.2;
        }
      }
    }
  }

  .no-outlets {
    width: 100%;
    text-align: left;
    color: var(--color-grey-grey100);
  }
}

.select-user {
  width: 300px;
  margin: var(--text-md) 0;
}

.permissions-header {
  color: var(--color-grey-grey100);
  margin-bottom: var(--space-unit-md);
}
