@import "../../../styles/constants.scss";

.footer {
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  bottom: 0px;
  right: 0px;
  height: var(--footer-height);
  background-color: "transparent";
  color: var(--color-black);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .footer-content {
    position: relative;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-logo {
    fill: var(--color-black);
    height: 32px;
    width: 129px;
  }
  .app-version {
    font-size: var(--text-xs);
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .footer-feedback-anchor {
    position: absolute;
    right: var(--space-unit-lg);
    display: flex;
    align-items: center;
  }

  .footer-feedback-text {
    font-weight: 500;
  }

  .footer-feedback-icon {
    margin-right: var(--space-unit-xs);
  }

  @media (max-width: $mobile-breakpoint-1) {
    bottom: var(--bottombar-height);

    .footer-feedback-anchor {
      position: static;
      right: unset;
    }
  }
}
