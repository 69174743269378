@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.product-outlet-panel {
  // remove side padding from expandable panel headers
  & > div {
    padding-left: 0;
    padding-right: 0;
  }

  .product-outlet-header {
    display: flex;
    flex-direction: column;

    .product-outlet-header-details {
      display: inline-flex;

      @media (max-width: $mobile-breakpoint-3) {
        width: calc(100vw - 2 * var(--space-unit-lg));
      }

      .product-outlet-header-detail-stock {
        margin-right: var(--space-unit-lg);
        white-space: nowrap;
      }

      .product-outlet-header-detail-item > span {
        width: 3ch;
        text-align: left;
      }
    }
  }
}

.product-outlet-container-wrapper {
  display: grid;
  grid-gap: var(--space-unit-xxl);
  grid-template-columns: repeat(auto-fill, minmax(249px, 1fr));
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &-group {
    width: 100%;
    padding-bottom: var(--space-unit-lg);
    > * {
      height: 100%;
    }
  }

  @media (max-width: $mobile-breakpoint-1) {
    grid-gap: var(--space-unit-lg);
  }

  @media (max-width: $mobile-breakpoint-2) {
    &-group {
      padding: unset;
    }
  }
}

.product-outlet {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-card {
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;

  &.diana-card {
    border-color: var(--color-grey-greenish);
  }

  .product-card-body {
    display: flex;
    align-items: center;
    flex-direction: column;

    .product-card-beverage-description {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 184px;
      margin-bottom: var(--space-unit-md);

      .product-card-beverage-logo {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
        width: 92px;
        height: 92px;
        margin-bottom: var(--space-unit-xs);
        border-radius: 50%;
        flex-shrink: 0;
      }

      .product-card-beverage-fallback-logo {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .product-card-beverage-type {
        color: var(--color-grey-grey100);
        margin-top: var(--space-unit-xxs);
        margin-bottom: var(--space-unit-lg);
      }
    }

    .product-card-order-button {
      margin-top: var(--space-unit-md);
      .diana-button {
        padding: var(--space-unit-xs) var(--space-unit-xl);
      }
    }
  }

  &.loading {
    animation: pulse 3s infinite, fade-in 0.5s 0.5s forwards;

    * {
      color: transparent !important;
    }
    .product-card-beverage-description {
      width: 100%;
    }
    .product-card-beverage-fallback-logo,
    .product-card-beverage-title,
    .product-card-beverage-type {
      background-color: var(--color-grey-grey25) !important;
    }

    .product-card-beverage-title,
    .product-card-beverage-type {
      min-width: 100%;
      min-height: var(--space-unit-md);
    }
  }
}
