@import "../../../styles/constants.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-unit-lg);

  @media (max-width: $mobile-breakpoint-3) {
    margin: unset;
    padding: var(--space-unit-lg) var(--space-unit-lg) 0 var(--space-unit-lg);
  }
}
