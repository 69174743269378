@import "../../styles/constants.scss";

.outlet-opening-hours {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .outlet-opening-hours-set {
    display: flex;
    padding: var(--space-unit-md) 0 0 0;
    border-bottom: 1px solid var(--color-grey-grey25);
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .outlet-opening-hour-days {
      display: flex;
      margin-right: var(--space-unit-md);
      margin-bottom: var(--space-unit-md);

      .outlet-weekday {
        border-radius: 10px;
        background-color: var(--color-white);
        border: 1px solid var(--color-grey-grey50);
        width: var(--space-unit-lg);
        height: var(--space-unit-lg);
        display: block;
        text-align: center;
        line-height: var(--space-unit-lg);
        margin-right: var(--space-unit-xs);
        cursor: pointer;

        &:hover {
          border-color: var(--color-black);
        }

        &.active {
          background-color: var(--color-black);
          border-color: var(--color-black);
          color: var(--color-white);

          &:hover {
            border-color: var(--color-grey-grey50);
          }
        }
      }
    }

    .outlet-opening-hour-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: var(--space-unit-md);
      flex: 1;

      > .outlet-hour {
        padding: 0px var(--space-unit-xs);
      }

      .outlet-hour {
        min-width: auto;
        @media (max-width: $mobile-breakpoint-1) {
          flex-grow: 1;
        }

        .list {
          width: 100%;
        }
      }
    }

    .delete-set-button {
      cursor: pointer;
      stroke: var(--color-alert-alert100);
    }

    .delete-set-text {
      cursor: pointer;
      color: var(--color-alert-alert100);
      margin-left: var(--space-unit-xxs);
    }
  }

  .add-set-button {
    align-self: center;
  }

  &.read-only {
    margin-top: var(--space-unit-md);
    pointer-events: none;
    .outlet-weekday.active {
      background-color: var(--color-grey-grey100) !important;
      border-color: var(--color-grey-grey100) !important;
    }
  }
}
