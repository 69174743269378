.performance-chart {
  &-wrapper {
    flex: 1;
    max-width: 100%;
    font-family: var(--font-primary);
  }

  .grid {
    line {
      stroke: var(--color-grey-grey100); /* Need to change this */
    }
  }

  .today-line {
    stroke: var(--color-white);
  }

  .yesterday-line {
    stroke: var(--color-grey-grey100);
  }

  .axis.y {
    .tick {
      text {
        text-anchor: end !important;
        fill: var(--color-grey-grey100);
      }
    }
  }
}
