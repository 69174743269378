@import "../../styles/constants.scss";

.container {
  align-items: stretch;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-right: var(--space-unit-xxl);

  @media (max-width: $mobile-breakpoint-1) {
    margin-bottom: var(--space-unit-xxl);
    margin-right: 0;
  }
}
.titleWrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--space-unit-lg);

  .title {
    text-align: left;

    @media (max-width: $mobile-breakpoint-3) {
      margin-left: var(--space-unit-lg);
    }
  }
}

.recentSales {
  .cardFooter {
    padding: var(--space-unit-md);
  }

  .dateText {
    align-items: center;
    display: flex;
  }

  @media (max-width: $mobile-breakpoint-3) {
    margin: 0 var(--space-unit-lg);
    padding: 0;

    .header > div {
      width: 100%;

      .recentSalesTabGroup {
        justify-content: space-between;

        .recentSalesTab {
          margin: 0px 2px 0px 0px;

          &:last-child {
            margin-right: 0px;
          }
          > span {
            font-size: var(--font-size);
          }
        }
      }
    }

    .dateText {
      display: none;
    }

    .cardFooter {
      padding: var(--space-unit-sm);
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.recentSalesCard {
  display: flex;
  justify-content: space-between;
  padding: var(--space-unit-lg) 0 0;
  align-items: stretch;

  .performanceCardWrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex: 1;
  }

  .performanceCardWrapperMargin {
    // align live performance and recent performance cards
    margin-right: calc(var(--space-unit-xxl) + var(--space-unit-xs) + 2px);
  }

  .performanceChart {
    flex: unset;
  }

  @media (max-width: $mobile-breakpoint-3) {
    flex-direction: column;
    padding-top: 0;

    .performanceCardWrapper {
      flex-direction: column;
    }
  }

  @media (max-width: $mobile-breakpoint-1) {
    flex-wrap: wrap;

    .performanceCardWrapper {
      flex-wrap: wrap;
      min-width: 100%;
    }

    .performanceCardWrapperMargin {
      margin-right: 0;
    }

    .performanceChart {
      flex: 1;
    }
  }
}

.livePerformanceTitleWrapper {
  display: flex;
  align-items: center;
  color: var(--color-grey-grey50);
  margin-bottom: var(--space-unit-md);
  text-align: left;
  padding-top: var(--space-unit-lg);
  @media (max-width: $mobile-breakpoint-3) {
    flex-direction: column;
  }
}

.legendWrapper {
  color: var(--color-white);
  display: flex;
  align-items: center;
  margin-left: var(--space-unit-sm);

  @media (max-width: $mobile-breakpoint-3) {
    justify-content: flex-end;
  }
}

.livePerformanceToday {
  border: 1.5px solid var(--color-white);
  border-radius: 2.5px;
  margin-right: var(--space-unit-xs);
  min-width: var(--space-unit-lg);
}

.livePerformanceAverage {
  border: 1.5px solid var(--color-grey-grey100);
  border-style: dashed;
  border-radius: 2.5px;
  margin-left: var(--space-unit-md);
  margin-right: var(--space-unit-xs);
  min-width: var(--space-unit-lg);
}

.livePerformanceTitle {
  color: var(--color-white);
  flex: 1;

  @media (max-width: $mobile-breakpoint-3) {
    justify-content: flex-start;
    width: 100%;
  }
}
