@import "../../styles/constants.scss";

.pressurechamber-info-wrapper {
  .keg-summary {
    border-color: var(--color-black);

    &-title {
      display: flex;
      flex-direction: row;
      margin-bottom: var(--space-unit-xs);

      &--left {
        margin-right: var(--space-unit-md);
      }
    }

    .keg-label {
      color: var(--color-grey-grey100);
    }

    .keg-brand--initials {
      margin-bottom: 0;
    }
  }
}
