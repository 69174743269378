.settings-outlets {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  padding: var(--space-unit-xxl) 0;

  .settings-outlets-intro {
    margin-bottom: var(--space-unit-md);
  }
  .settings-outlets-text {
    margin-bottom: var(--space-unit-xxl);
  }
}
