@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.device-card {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 10px;
  box-sizing: border-box;
  height: fit-content;
  justify-content: space-between;
  min-width: 256px;
  position: relative;

  &:hover {
    z-index: 1;
  }

  &.real {
    opacity: 0;
    animation: fade-in 0.5s forwards;
    border-color: var(--color-grey-greenish);
  }

  &.error {
    border-color: var(--color-alert-alert50);
  }

  &.loading {
    background-color: var(--color-grey-grey25);
    opacity: 0;
    animation: pulse 3s infinite, fade-in 0.5s 0.5s forwards;
  }

  .device-card-body {
    box-sizing: border-box;
    width: 100%;
  }

  .device-card-menu-wrapper {
    margin-top: var(--space-unit-xs);
    margin-right: calc(var(--space-unit-xs) + var(--space-unit-xxs));
    position: absolute;
    right: 0;
    top: 0;
  }

  .optional-buy-button {
    min-width: 180px;
    margin-top: var(--space-unit-xs);
  }

  .device-card-body-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .devicecard-editbutton {
    border: 1px solid var(--color-white);
    border-bottom: 0;
    border-radius: 4px 0px 0px 0px;
    border-right: 0;
    bottom: 0;
    position: absolute;
    right: 0;
  }
}
