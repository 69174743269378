@import "../../styles/animations.scss";
@import "../../styles/constants.scss";

.add-user-button {
  flex-shrink: 0;

  @media (max-width: $mobile-breakpoint-2) {
    align-self: center;
    margin-top: var(--space-unit-md);
  }

  .diana-icon {
    fill: var(--color-black);
  }
}

.loading-users-container {
  border-top: 1px solid var(--color-grey-grey50);

  .loading-users {
    border-radius: 6px;
    background-color: var(--color-grey-grey50);
    margin: var(--space-unit-md) 0;
    max-width: 40%;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      linear-gradient(#e7e7e7 20px, transparent 0);
    background-repeat: repeat-y;
    background-size: 50px 1em, /* highlight */ 100% 1em;
    background-position: -50px 0, /* highlight */ 0 0;
    animation: shine 1s infinite;
  }
}

.users-container {
  padding: 0 var(--space-unit-lg);
  width: 100%;

  @media (max-width: $mobile-breakpoint-2) {
    padding: 0;
  }
}

.user-card {
  &:not(:first-child) {
    margin-top: var(--space-unit-md);
  }
}

.remove-outlet-modal-header {
  color: var(--color-alert-alert100);
}
