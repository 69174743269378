@import "../../../styles/constants.scss";

@media (max-width: $mobile-breakpoint-1) {
  .sidebar-nav-button-item {
    min-width: unset !important;

    .diana-icon {
      margin-right: 0;
    }
  }
}

.tablet-content-nav-button::after {
  position: absolute;
  content: attr(data-after);
  height: 50px;
  left: calc(100% - 14px);
  border: 1px solid var(--color-grey-greenish);
  border-left: none;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  padding: 0 var(--space-unit-xs);
  border-radius: 0 10px 10px 0;
  text-transform: none;

  /* Although this shouldn't be here 
   * (case it makes global changes harder)
   * didn't really came with a better solution for now 
   */
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.33rem;
  color: var(--color-black);
}
