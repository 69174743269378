@import "../../styles/constants.scss";
@import "../../styles/animations.scss";

.login-page {
  height: 100vh;
  display: flex;
  overflow-x: hidden;

  @media (max-width: $mobile-breakpoint-2) {
    flex-direction: column;
  }

  .contentful-loading {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
  }
  .contentful-error {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .login-azure-flow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    .text-content {
      min-height: 400px;
      margin: var(--space-unit-lg);
      > * {
        margin: var(--space-unit-lg) 0;
      }
    }
  }

  .login-ready {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: var(--color-background-white);
    overflow: hidden;
  }

  .logo-container {
    margin-top: var(--space-unit-xxl);

    @media (max-width: $mobile-breakpoint-2) {
      margin-top: 0;
      text-align: center;
    }
  }

  .login-background {
    flex: 1;

    .login-background-image {
      display: flex;
      height: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      flex: 1 1;
      opacity: 0;
      z-index: 2;
      fill: var(--color-white);
      overflow: hidden;
      animation: fade-in 3s 1s forwards;

      &.error {
        background-color: var(--color-keg-green);
      }

      &.instant {
        animation: fade-in 3s forwards;
      }
      &.frame {
        --n: 0;
        --d: 0;
        padding-bottom: calc(var(--n) / var(--d) * 100%);
        position: relative;
      }
      &.frame > * {
        overflow: hidden;
        position: absolute;
        top: 0;
      }
      &.frame > img,
      &.frame > video {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
      .bg-image {
        animation: floating 75s infinite linear;
      }
      .image-anim-enter {
        opacity: 0;
      }
      .image-anim-enter.image-anim-enter-active {
        opacity: 1;
        transition: opacity 2s;
      }
      .image-anim-exit {
        opacity: 1;
      }
      .image-anim-exit.image-anim-exit-active {
        opacity: 0;
        transition: opacity 2s;
      }
    }
  }

  .login-form {
    background-color: var(--color-white);
    min-width: 480px;
    transform: scale(1, 0);
    transform-origin: right;
    animation: slide-from-right 0.3s 0.5s ease-out forwards;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: calc(480px + 2 * var(--space-unit-xxl));
    width: 25%;
    padding: var(--space-unit-xxl) var(--space-unit-xxl);
    box-sizing: border-box;
    overflow-y: auto;

    .login-lower {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: space-between;
      opacity: 0;
      animation: fade-in 1s 0.5s ease-in forwards;
    }

    @media (max-width: $mobile-breakpoint-2) {
      min-width: unset;
      max-width: unset;
      width: 100%;
      padding: var(--space-unit-lg);
    }

    .login-form-language-privacy {
      display: flex;
      justify-content: space-between;
      animation-delay: 1s;
      flex-direction: row;
      align-items: center;

      @media (max-width: $mobile-breakpoint-2) {
        margin-top: var(--space-unit-lg);
      }

      .login-privacy {
        text-decoration: none;
        color: var(--color-grey-grey100);
        font-weight: 500;
        font-size: var(--text-ssm);
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: var(--space-unit-xxl);

      .cancel-button {
        margin-right: var(--space-unit-xs);
      }
    }
    .login-form-middle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: var(--space-unit-md) 0;

      @media (max-width: $mobile-breakpoint-2) {
        align-items: center;
        margin-top: var(--space-unit-xxl);
      }

      @media (max-width: $mobile-breakpoint-3) {
        margin-top: var(--space-unit-lg);
      }

      .textfield {
        width: 100%;
        margin-bottom: var(--space-unit-md);
      }

      .page-title {
        text-align: start;
        margin-top: var(--space-unit-lg);

        @media (max-width: $mobile-breakpoint-2) {
          margin-top: var(--space-unit-md);
        }
      }

      .title-dark {
        color: var(--color-grey-grey100);
      }
      .login-form-options {
        display: flex;
        justify-content: space-between;
        margin-top: 13px; // var(--space-unit-lg) - ErrorTextInput's error label height

        .login-option {
          display: flex;

          &.login-remember-me {
            white-space: nowrap;
          }
        }
      }
      .login-form-container {
        width: 100%;
        margin-top: var(--space-unit-xxl);

        @media (max-width: $mobile-breakpoint-2) {
          margin: var(--space-unit-xxl) 0 var(--space-unit-lg);
        }

        .password-field {
          margin-top: var(--space-unit-lg);
        }
      }

      .reset-password-confirm-input {
        margin-top: 13px; // var(--space-unit-lg) - ErrorTextInput's error label height
      }
    }

    .login-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      animation-delay: 2s;
      animation-duration: 1s;
      text-align: left;

      @media (max-width: $mobile-breakpoint-2) {
        text-align: center;
      }
    }
  }

  .fade {
    opacity: 0;
    animation: fade-in 0.4s forwards;
  }
  .fade-in {
    opacity: 0;
    animation: fade-in 1s 0.3s forwards;
  }
  .fade-out {
    opacity: 1;
    animation: fade-out 0.5s forwards;
  }
  .fade-and-slide {
    opacity: 0;
    animation: fade-in-from-below 0.3s forwards;
  }
}

@keyframes slide-in-panel {
  from {
    width: 480px;
  }
  to {
    width: 100%;
  }
}

.onboarding {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  .login-form-middle {
    @media (max-width: $mobile-breakpoint-2) {
      margin-top: 0;
    }
  }

  .onboarding-form-container {
    display: flex;
    flex-direction: column;
    margin: var(--space-unit-lg) 0;
    width: 100%;

    .textfield-container {
      margin-top: var(--space-unit-xxl);
      width: 100%;

      @media (max-width: $mobile-breakpoint-2) {
        margin-top: var(--space-unit-lg);
      }

      .reset-password-confirm-input {
        margin-top: var(--space-unit-md);
      }
    }

    .checkbox-container {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-unit-xxl);

      @media (max-width: $mobile-breakpoint-2) {
        margin-top: var(--space-unit-lg);
      }

      .checkbox:not(:first-child) {
        margin-top: var(--space-unit-lg);

        @media (max-width: $mobile-breakpoint-2) {
          margin-top: var(--space-unit-md);
        }
      }
    }
  }
}

.onboarding-progress {
  stroke: var(--color-white);
}
.onboarding-progress-fill {
  stroke: var(--color-white);
  fill: var(--color-white);
  stroke-width: 6;
  &.completed {
    fill: var(--color-keg-green);
  }
}
.sliding-onboarding {
  min-width: 100%;
}

.background-enter {
  transform: translate(calc(100% + (calc(var(--space-unit-xxl) * 2))));
  opacity: 0;
}
.background-enter.background-enter-active {
  transform: translate(0%);
  opacity: 1;
  transition: transform 400ms ease-in-out, opacity 200ms;
}
.background-exit {
  transform: translate(0%);
  opacity: 1;
}
.background-exit.background-exit-active {
  transform: translate(calc(-100% - (calc(var(--space-unit-xxl) * 2))));
  opacity: 0;
  transition: transform 400ms ease-in-out, opacity 200ms;
}

.background-inverse-enter {
  transform: translate(calc(-100% - (calc(var(--space-unit-xxl) * 2))));
}
.background-inverse-enter.background-inverse-enter-active {
  transform: translate(0%);
  transition: transform 400ms ease-in-out;
}
.background-inverse-exit {
  transform: translate(0%);
  opacity: 1;
}
.background-inverse-exit.background-inverse-exit-active {
  transform: translate(calc(100% + (calc(var(--space-unit-xxl) * 2))));
  opacity: 0;
  transition: transform 400ms ease-in-out, opacity 200ms;
}

.bg-image {
  animation: floating 75s infinite linear;
}
.image-anim-enter {
  opacity: 0;
}
.image-anim-enter.image-anim-enter-active {
  opacity: 1;
  transition: opacity 2s;
}
.image-anim-exit {
  opacity: 1;
}
.image-anim-exit.image-anim-exit-active {
  opacity: 0;
  transition: opacity 2s;
}

.grecaptcha-badge.hidden {
  visibility: hidden;
}
