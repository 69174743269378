@keyframes expand-horizontally {
  from {
    opacity: 0;
    transform: scale(0, 1);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(450px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes floating {
  from {
    transform: translate(0px, 0px) scale(1);
  }
  to {
    transform: translate(50px, 50px) scale(1.65);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes expand-width {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}

@keyframes expand-vertically {
  0% {
    transform: scale(1, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes expand {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes slide-from-right {
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes fade-in-from-below {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ripple {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes expand-and-border {
  0% {
    border-radius: 50%;
    transform: scale(0, 0);
  }
  100% {
    border-radius: 0%;
    transform: scale(1, 1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--color-grey-grey25);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 4px rgba(108, 210, 139, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(108, 210, 139, 0);
  }
}

@keyframes shine {
  to {
    background-position: 120% 0, /* move highlight to right */ 0 0;
  }
}

.pulse {
  animation: pulse 2s infinite;
}
