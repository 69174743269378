@import "../../styles/constants.scss";

.liveSales {
  background-color: var(--color-background-black);
  margin-bottom: var(--space-unit-xxxl);
  padding: var(--space-unit-lg);
  border-radius: var(--border-radius);

  @media (max-width: $mobile-breakpoint-1) {
    margin-bottom: var(--space-unit-xxl);
  }
  @media (max-width: $mobile-breakpoint-3) {
    margin-left: var(--space-unit-lg);
    margin-right: var(--space-unit-lg);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      color: var(--color-white);
    }
  }
}
