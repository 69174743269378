@import "../../../styles/constants.scss";

.cookies {
  background-color: var(--color-white) !important;
  bottom: var(--space-unit-md);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: var(--color-black);
  flex-grow: 1;
  left: var(--space-unit-md);
  padding: var(--space-unit-sm);
  position: fixed;
  text-align: left;
  width: 280px;
  word-break: initial;
  z-index: 3;

  @media (max-width: $mobile-breakpoint-2) {
    width: calc(100% - 2 * var(--space-unit-md));
  }

  .link {
    cursor: pointer;
    color: var(--color-primary);
    text-decoration: underline;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: var(--space-unit-sm);

    .buttonYes {
      flex: 1;
      justify-content: center;
    }
  }
}
